import styled, { css } from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths';
import zIndices from 'pubweb-smokey/dist/components/zIndex';

const dropDownStyling = css`
  @keyframes open-animation-mobile {
    0% {
      max-height: 0vh;
    }
    100% {
      max-height: 90vh;
    }
  }
  @keyframes open-animation {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 300px;
    }
  }

  @keyframes label-fade-transition {
    0% {
      color: ${Colors.accent.grey2.standard};
      font-size: 14px;
      left: 0;
      opacity: 1;
      top: 13px;
    }

    22% {
      left: 0;
    }

    45% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 14px;
      left: -25%;
      opacity: 0;
      top: 13px;
    }
    55% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      left: -25%;
      opacity: 0;
      top: -5px;
    }
    77% {
      left: -25%;
    }
    100% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      left: 0;
      opacity: 1;
      top: -5px;
    }
  }

  @keyframes label-fade-transition-outline {
    0% {
      color: ${Colors.accent.grey2.standard};
      font-size: 14px;
      left: 0;
      opacity: 1;
      top: 13px;
    }

    22% {
      left: 0;
    }

    45% {
      font-size: 14px;
      left: -25%;
      opacity: 0;
      top: 13px;
    }
    55% {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      left: -25%;
      opacity: 0;
      top: 1px;
    }
    77% {
      left: -25%;
    }
    100% {
      font-size: 11px;
      left: 0;
      opacity: 1;
      top: 1px;
    }
  }

  select {
    display: none;
  }
  label {
  }
  .dropdown-menu {
    display: none;
    left: 5vw;
    overflow: hidden;
    top: 50vh;
    transform: translateY(calc(-50% - 27px));
    position: fixed;
    width: 90%;
    z-index: ${zIndices.mobileDropDownMenu};
  }
  .dropdown-display {
    background-color: #ffffff;
    border: 0;
    border-radius: 5px;
    color: ${Colors.accent.grey1.standard};
    display: block;
    list-style-type: none;
    margin-top: 0;
    margin-bottom: 0;
    max-height: calc(80% - 32px);
    overflow-y: auto;
    padding: 5px;
    -webkit-overflow-scrolling: touch;

    li {
      border-radius: 5px;
      color: ${Colors.accent.grey1.standard};
      cursor: pointer;
      font-family: 'source-sans-pro', sans-serif;
      font-size: 16px;
      margin: 0;
      overflow: hidden;
      padding: 10px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;

      svg {
        display: none;
        right: 2px;
        position: absolute;
        top: 8px;
      }
      .highlight {
        border-radius: 5px;
        background-color: ${Colors.primary.claytonBlue.tint};
      }
      &.selected {
        background-color: ${Colors.primary.claytonBlue.hover};
        color: ${Colors.primary.white.standard};

        .highlight {
          background-color: ${Colors.primary.claytonBlue.hover};
        }
        svg {
          display: block;
          float: right;
          height: 25px;
          width: 25px;

          path {
            fill: ${Colors.primary.white.standard};
          }
        }
      }
      &.focus,
      &:hover {
        background-color: ${Colors.primary.claytonBlue.tint};

        &.selected {
          color: ${Colors.accent.grey1.standard};

          svg {
            path {
              fill: ${Colors.accent.grey1.standard};
            }
          }
        }
      }
    }
  }

  .dropdown-display::-webkit-scrollbar,
  .dropdown-display::-webkit-scrollbar,
  .dropdown-display::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  .dropdown-display::-webkit-scrollbar-thumb,
  .dropdown-display::-webkit-scrollbar-thumb,
  .dropdown-display::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: lightgray;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .dropdown-close {
    margin-left: auto;
    text-align: right;
  }
  .dropdown-filter {
    background: rgba(0, 0, 0, 0.5);
    content: 'open';
    display: none;
    font-size: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    top: 0;
    width: 100vw;
    z-index: ${zIndices.dropDownMenuModal};
  }
  &.open {
    .dropdown-menu {
      animation: open-animation-mobile 0.25s linear;
      display: flex;
      flex-direction: column;
      max-height: 90vh;
      margin-top: 18px;
    }
    .dropdown-filter {
      display: block;
    }
    .dropdown-close {
      display: block;
    }
  }
  .dropdown-chevron {
    margin-left: -26px;
  }

  &.suggestion-box {
    &.open {
      position: relative;

      .dropdown-display {
        border: 1px solid ${Colors.accent.grey4.standard};
        border-radius: 0;
        max-height: 280px;
        padding: 0;
        width: auto;

        li {
          border-radius: 0;
        }
      }
      .dropdown-menu {
        animation: open-animation 0.25s linear;
        box-shadow: 0 10px 30px -14px rgba(0, 0, 0, 0.15);
        display: block;
        left: 0;
        position: absolute;
        top: 24px;
        transform: none;
        width: 100%;
        z-index: ${zIndices.dropDownMenu};
      }
      .dropdown-filter {
        display: none;
      }
      .dropdown-close {
        display: none;
      }
    }

    &.open.outlined {
      .dropdown-menu {
        top: 36px;
      }
    }
  }

  @media screen and (min-width: ${variables.desktop_breakpoint}px) {
    &.open {
      position: relative;

      .dropdown-display {
        border: 1px solid ${Colors.accent.grey4.standard};
        border-radius: 0;
        max-height: 280px;
        padding: 0;
        width: auto;

        li {
          border-radius: 0;
        }
      }
      .dropdown-menu {
        animation: open-animation 0.25s linear;
        box-shadow: 0 10px 30px -14px rgba(0, 0, 0, 0.15);
        display: block;
        left: 0;
        position: absolute;
        top: 24px;
        transform: none;
        width: 100%;
        z-index: ${zIndices.dropDownMenu};
      }
      .dropdown-filter {
        display: none;
      }
      .dropdown-close {
        display: none;
      }
    }

    &.open.outlined {
      .dropdown-menu {
        top: 36px;
      }
    }
  }

  .error-message {
    color: ${Colors.accent.ladyBug.standard};
    font-size: 12px;
    height: 22px;
    letter-spacing: -0.2px;
    line-height: 1.83;
    margin-top: 0;
    padding-left: 1px;
    text-align: left;
  }
`;

const DropDownListStyles = styled.div`
  ${dropDownStyling}

  position: relative;

  button {
    background-color: #fff;
    border: 0;
    border-bottom: solid 1px ${Colors.accent.grey2.standard};
    box-sizing: border-box;
    color: ${Colors.primary.black.standard};
    display: block;
    font-family: 'source-sans-pro', sans-serif;
    font-size: 16px;
    height: 42px;
    line-height: 1.56;
    letter-spacing: -0.3px;
    overflow: hidden;
    outline: 0;
    padding: 9px 0 0 1px;
    position: relative;
    text-align: left;
    width: 100%;

    &:focus {
      border-color: ${Colors.primary.claytonBlue.standard};
    }

    label {
      color: ${Colors.accent.grey2.standard};
      display: block;
      font-size: 16px;
      line-height: 25px;
      pointer-events: none;
      position: absolute;
      top: 14px;
    }

    &:focus label {
      animation: label-fade-transition 0.3s linear;
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      display: block;
      top: -5px;
    }

    &.has-value:focus label {
      animation: none;
      color: ${Colors.primary.claytonBlue.standard};
    }

    &.has-value label {
      font-size: 11px;
      display: block;
      top: -5px;
    }

    &:focus ~ .dropdown-chevron > svg * {
      fill: ${Colors.primary.claytonBlue.standard};
    }
  }

  .dropdown-chevron {
    right: 2px;
    position: absolute;
    top: 21px;
  }

  &.open:hover {
    label {
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      display: block;
      top: -5px;
    }
  }

  .dropdown-selected-text {
    display: block;
    font-family: 'source-sans-pro', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 19px);
  }

  &.error {
    button,
    button:focus {
      border-color: ${Colors.accent.ladyBug.standard};
    }

    button label,
    button:focus label {
      color: ${Colors.accent.ladyBug.standard};
    }

    & button ~ .dropdown-chevron svg * {
      fill: ${Colors.accent.ladyBug.standard};
    }
  }

  &.disabled {
    cursor: not-allowed;
    button {
      border-color: ${Colors.accent.grey3.standard};

      pointer-events: none;

      label {
        color: ${Colors.accent.grey3.standard};
      }
    }
  }

  &.has-background {
    button {
      background-color: ${Colors.secondary.grey6.standard};
      padding-left: 10px;
    }
  }

  &.outlined {
    button {
      border: 1px solid ${Colors.accent.grey2.standard};
      border-radius: 2px;
      padding-bottom: 35px;
      padding-left: 10px;
      padding-top: 17px;

      &:focus {
        border-color: ${Colors.primary.claytonBlue.standard};
      }
    }

    .dropdown-chevron {
      right: 6px;
    }
  }

  &.compact {
    button {
      border: 1px solid ${Colors.accent.grey2.standard};
      font-family: 'source-sans-pro', sans-serif;
      padding-left: 11px;
      padding-top: 1px;

      label {
        top: 8px;
      }
      &:focus {
        border-color: ${Colors.primary.claytonBlue.standard};

        label {
          animation: none;
          font-size: 16px;
          top: 8px;
        }
      }
    }

    .dropdown-chevron {
      right: 4px;
      top: 14px;
    }

    button.has-value {
      label {
        display: none;
        top: 14px;
      }
    }
  }
`;

export default DropDownListStyles;

export const SuggestionBoxStyles = styled.div`
  ${dropDownStyling}

  position: relative;

  .dropdown-chevron {
    right: 2px;
    position: absolute;
    top: 21px;
  }

  &.open,
  &.has-value {
    label {
      animation: none;
      color: ${Colors.primary.claytonBlue.standard};
      font-size: 11px;
      display: block;
      top: -5px;
    }
  }

  &.open {
    .dropdown-chevron > svg * {
      fill: ${Colors.primary.claytonBlue.standard};
    }
  }

  .suggestion-clear {
    position: absolute;
    right: 2px;
    top: 18px;
  }

  .dropdown-textbox input {
    padding-right: 20px;
  }

  &.error {
    & .dropdown-chevron svg *,
    & .suggestion-clear svg * {
      fill: ${Colors.accent.ladyBug.standard};
    }
  }

  &.open {
    position: relative;

    .dropdown-display {
      border: 1px solid ${Colors.accent.grey4.standard};
      border-radius: 0;
      max-height: 280px;
      padding: 0;
      width: auto;

      li {
        border-radius: 0;
      }
    }
    .dropdown-menu {
      animation: open-animation 0.25s linear;
      box-shadow: 0 10px 30px -14px rgba(0, 0, 0, 0.15);
      display: block;
      left: 0;
      position: absolute;
      transform: none;
      width: 100%;
      z-index: ${zIndices.dropDownMenu};
    }
    .dropdown-filter {
      display: none;
    }
    .dropdown-close {
      display: none;
    }
  }

  &.compact {
    .dropdown-chevron,
    .suggestion-clear {
      right: 8px;
      top: 16px;
    }
  }

  &.outlined {
    .suggestion-clear {
      right: 6px;
      top: 22px;
    }

    .dropdown-chevron {
      right: 6px;
      top: 21px;
    }
  }

  .suggestion-box {
    .dropdown-menu {
      top: auto;
    }
  }
`;
