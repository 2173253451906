import PropTypes from 'prop-types';
import React from 'react';
import IconStyles from './Icon.styled';

const Icon = ({ color = '#25292D', children, ...props }) => {
  return (
    <IconStyles {...props} color={color}>
      {children}
    </IconStyles>
  );
};

Icon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  color: PropTypes.string,
};

export default Icon;
