import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  scrollLock,
  scrollUnlock,
  scrollLocked,
  isMobile,
} from 'pubweb-smokey/dist/utils/utils';
import { useOnClickOutside } from 'pubweb-smokey/dist/hooks/useOnClickOutside';
import { useDebounce } from 'pubweb-smokey/dist/hooks/useDebounce';
import {
  componentItemsEqual,
  getComponentItemValue,
} from 'pubweb-smokey/dist/utils/utils';
import * as variables from 'pubweb-smokey/dist/components/GridSystem/_vars_widths';

import Colors from 'pubweb-smokey/dist/colors';
import Icon from '@components/Shared/Icon/Icon';
import ChevronDown from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import CheckMark from 'pubweb-smokey/dist/images/svg/iconography-16x16/checkmark.svg';
import Cross from 'pubweb-smokey/dist/images/svg/iconography-16x16/x.svg';
import TextField from '../TextField/TextField';

import DropDownListStyles, { SuggestionBoxStyles } from './DropDownList.styled';

const DropDownList = (
  {
    automationId = '',
    children,
    disabled,
    id,
    errorMessage,
    highlightMatch = false,
    label = '',
    ariaLabel,
    items,
    onChange,
    onTextChange,
    showClearIcon = false,
    defaultValue,
    selectedItem,
    selectedValue,
    dropDownStyle = 'primary',
    selectRef,
    textBoxRef,
    disableFilter = false,
    ...props
  },
  RENDER_AS_SUGGESTION_BOX
) => {
  const getItemByValue = (items, value) => {
    if (!items || value === undefined || value === null) {
      return null;
    }

    return items.filter((item) => getComponentItemValue(item) == value)[0];
  };

  const [currentItems, setCurrentItems] = useState(items);
  const [currentSelectedValue, setCurrentSelectedValue] = useState(
    defaultValue || selectedValue
  );
  const defaultSelectedItem =
    selectedItem || getItemByValue(items, currentSelectedValue);
  const [currentSelectedItem, setCurrentSelectedItem] = useState(
    selectedItem || defaultSelectedItem
  );
  const [focusedItem, setFocusedItem] = useState(null);
  const [opened, setOpened] = useState(false);
  const [suggestedText, setSuggestedText] = useState(
    defaultSelectedItem ? defaultSelectedItem.text || defaultSelectedItem : ''
  );
  const [hasValueClass, setHasValueClass] = useState('');
  const [unlockScroll, setUnlockScroll] = useState(false);

  const clickableAreaRef = useRef();
  const menuDisplayRef = useRef();
  const hardTextBoxref = useRef();
  const hardSelectRef = useRef();
  const internalTextBoxRef = textBoxRef || hardTextBoxref;
  const internalSelectRef = selectRef || hardSelectRef;
  const useFilter = !disableFilter;

  let mouseWheelEventAttached = false;

  useEffect(() => {
    if (internalSelectRef.current) {
      const newSelectedItem = getItemByValue(items, currentSelectedValue);

      updateSuggestedText(newSelectedItem);

      if (newSelectedItem) {
        setCurrentSelectedItem(newSelectedItem);
      }

      setCurrentItems(items);
    }
    //eslint-disable-next-line
  }, [items]);

  useEffect(() => {
    if (selectedItem) {
      updateSuggestedText(selectedItem);
      setCurrentSelectedItem(selectedItem);
      setCurrentSelectedValue(getComponentItemValue(selectedItem));
    }

    //eslint-disable-next-line
  }, [selectedItem]);

  useEffect(() => {
    const newSelectedItem = getItemByValue(currentItems, selectedValue);
    if (newSelectedItem) {
      updateSuggestedText(newSelectedItem);
      setCurrentSelectedItem(newSelectedItem);
      setCurrentSelectedValue(getComponentItemValue(newSelectedItem));
    }
    //eslint-disable-next-line
  }, [selectedValue]);

  useEffect(() => {
    if (
      isMobile(variables.desktop_breakpoint) &&
      RENDER_AS_SUGGESTION_BOX !== true &&
      opened &&
      !scrollLocked()
    ) {
      scrollLock();
      setUnlockScroll(true);
    } else if (unlockScroll) {
      scrollUnlock();
      setUnlockScroll(false);
    }
  }, [RENDER_AS_SUGGESTION_BOX, opened, unlockScroll]);

  useEffect(() => {
    attachMouseWheelEvent();

    if (!automationId) {
      console.warn('Automation id was not provided.');
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (suggestedText) {
      setHasValueClass('has-value');
    } else {
      setHasValueClass('');
    }
  }, [suggestedText]);

  const suppressScrolling = (e) => {
    const menuDisplay = menuDisplayRef.current;
    if (menuDisplay) {
      if (e.target === menuDisplay || menuDisplay.contains(e.target)) {
        if (e.wheelDelta > 0 && menuDisplay.scrollTop <= 0) {
          e.preventDefault();
        } else if (
          e.wheelDelta < 0 &&
          menuDisplay.scrollTop + menuDisplay.offsetHeight >=
            menuDisplay.scrollHeight
        ) {
          e.preventDefault();
        }
      }
    }
  };

  const attachMouseWheelEvent = () => {
    const menuDisplay = menuDisplayRef.current;

    if (menuDisplay && !mouseWheelEventAttached) {
      // so this is to make sure the event gets attached, we can't use "onWheel" event from react because it's a passive event, we need the event
      // to remain active and have to manually attach the event
      menuDisplay.addEventListener('mousewheel', suppressScrolling, {
        passive: false,
      });
      mouseWheelEventAttached = true;
    } else {
      setTimeout(attachMouseWheelEvent, 1000);
    }
  };

  const handleDocumentClick = (e) => {
    const clickableArea = clickableAreaRef.current;
    if (
      !clickableArea ||
      (e.target !== clickableArea && !clickableArea.contains(e.target))
    ) {
      setOpened(false);
    }
  };

  if (typeof document !== 'undefined') {
    //eslint-disable-next-line
    useOnClickOutside(clickableAreaRef, handleDocumentClick);
  }

  const handleKeyDown = (e) => {
    // this handles the keyboard functionality for the drop down menu
    const menuDisplay = menuDisplayRef.current;
    let nextItemIndex = currentItems.indexOf(
      getItemByValue(currentItems, getComponentItemValue(currentSelectedItem))
    );

    if (menuDisplay) {
      if (e.keyCode === 38 || e.keyCode === 40) {
        if (e.keyCode === 40) {
          // down arrow
          nextItemIndex++;
        } else if (e.keyCode === 38) {
          // up arrow
          nextItemIndex--;
        }

        if (nextItemIndex >= currentItems.length) {
          nextItemIndex = 0;
        }

        if (nextItemIndex < 0) {
          nextItemIndex = currentItems.length - 1;
        }

        const nextItem = currentItems[nextItemIndex];
        const focusedMenuItem = menuDisplay.children[nextItemIndex];

        if (nextItem === currentSelectedItem) {
          return;
        }

        // if the menu item to focus on is out of view, scroll to bring it into view
        if (
          focusedMenuItem.offsetTop >=
            menuDisplay.scrollTop + menuDisplay.clientHeight ||
          focusedMenuItem.offsetTop <= menuDisplay.scrollTop
        ) {
          menuDisplay.scrollTop = focusedMenuItem.offsetTop;
        }

        if (opened) {
          setFocusedItem(focusedMenuItem);
        }

        setCurrentSelectedItem(nextItem);

        if (onChange && RENDER_AS_SUGGESTION_BOX !== true) {
          onChange(nextItem.__hiddenObject || nextItem);
        }

        e.preventDefault();
        e.stopPropagation();
      } else if (e.keyCode === 13) {
        // enter key
        // this closes the dropdown menu to confirm the user's highlighted selection
        if (focusedItem) {
          setFocusedItem(null);
          setOpened(false);
          updateSuggestedText(currentSelectedItem);

          if (RENDER_AS_SUGGESTION_BOX === true) {
            onChange(currentSelectedItem.__hiddenObject || currentSelectedItem);
          }

          e.preventDefault();
          e.stopPropagation();
        }
      } else if (e.keyCode === 9) {
        // tab
        // this ensures the dropdown menu closes when the user tabs off the dropdown
        setOpened(false);
      }
    }
  };

  const handleItemClick = (e, item) => {
    // if a selection is clicked and there's a textbox, then make sure it's focused
    if (internalTextBoxRef.current) {
      internalTextBoxRef.current.focus();
    }

    setFocusedItem(null);
    setOpened(false);
    setCurrentSelectedItem(item);
    updateSuggestedText(item);

    if (onChange) {
      onChange(item.__hiddenObject || item);
    }

    e.stopPropagation();
  };

  const updateSuggestedText = (item) => {
    if (item === undefined || item === null) {
      return setSuggestedText(suggestedText);
    }

    if (props.itemTextDisplay === 'value') {
      return setSuggestedText(item.value || item);
    }

    return setSuggestedText(item.text || item);
  };

  const dropDownRender = (renderedItems) => {
    // create the menu that will display in the dropdown list, if there are no items, render nothing
    const renderedMenu =
      renderedItems && renderedItems.length > 0 ? (
        <menu className="dropdown-display" ref={menuDisplayRef}>
          {renderedItems.map((item, index) => {
            return (
              <li
                className={
                  componentItemsEqual(item, currentSelectedItem)
                    ? 'selected'
                    : ''
                }
                key={`${id || ''}-${index}`}
                onMouseDown={(e) => handleItemClick(e, item)}
              >
                {item.__renderedComponent || item.text || item}
                <Icon color={Colors.primary.claytonBlue.standard}>
                  <CheckMark />
                </Icon>
              </li>
            );
          })}
        </menu>
      ) : null;

    return (
      <>
        <select
          aria-label={ariaLabel || label}
          onChange={() => {
            /* this is here to kill a warning */
          }}
          value={
            getComponentItemValue(currentSelectedItem) ||
            (internalTextBoxRef.current ? internalTextBoxRef.current.value : '')
          }
          id={id || ''}
          ref={internalSelectRef}
          key={(id || '') + '-select'}
          name={id || ''}
        >
          <option key={`${id || ''}-novalue`} value=""></option>
          {renderedItems && renderedItems.length > 0 ? (
            renderedItems.map((item, index) => (
              <option
                key={`${id || ''}-${index}`}
                value={getComponentItemValue(item)}
              >
                {item.text || item}
              </option>
            ))
          ) : defaultValue ? (
            <option value={defaultValue}>{defaultValue}</option>
          ) : internalTextBoxRef.current ? (
            <option value={internalTextBoxRef.current.value}>
              {internalTextBoxRef.current.value}
            </option>
          ) : null}
        </select>
        {showClearIcon && suggestedText ? (
          <Icon
            className="suggestion-clear"
            color={Colors.accent.grey1.standard}
            onClick={(e) => {
              setCurrentSelectedItem(null);
              setCurrentItems(items);
              updateSuggestedText('');

              internalTextBoxRef.current.focus();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Cross />
          </Icon>
        ) : (
          <Icon
            color={Colors.accent.grey1.standard}
            className="dropdown-chevron"
            onClick={(e) => {
              setOpened(!opened);

              if (internalTextBoxRef.current) {
                internalTextBoxRef.current.focus();
              }
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <ChevronDown />
          </Icon>
        )}
        {renderedMenu || children ? (
          <>
            <div
              className="dropdown-menu"
              onClick={(e) => {
                setOpened(false);

                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className="dropdown-close">
                <Icon color={Colors.primary.white.standard}>
                  <Cross />
                </Icon>
              </div>
              {renderedMenu || (
                <div className="dropdown-display">{children}</div>
              )}
            </div>
            <div
              onClick={(e) => {
                setOpened(false);

                e.preventDefault();
                e.stopPropagation();
              }}
              className="dropdown-filter"
            ></div>
          </>
        ) : null}
      </>
    );
  };

  const classes = [];

  if (props.className) {
    classes.push(props.className);
  }

  if (errorMessage) {
    classes.push('error');
  }

  if (opened) {
    classes.push('open');
  }

  if (disabled) {
    classes.push('disabled');
  }

  if (dropDownStyle.toLowerCase().trim() === 'primary-background') {
    classes.push('has-background');
    dropDownStyle = 'primary';
  }

  if (dropDownStyle.toLowerCase().trim() === 'compact') {
    classes.push('compact');
  }

  if (dropDownStyle.toLowerCase().trim() === 'outlined') {
    classes.push('outlined');
  }

  if (RENDER_AS_SUGGESTION_BOX !== true) {
    return (
      <DropDownListStyles
        id={(id || '') + '-container'}
        ref={clickableAreaRef}
        key={id}
        {...props}
        className={'dropdown-list ' + classes.join(' ')}
      >
        <button
          id={(id || '') + '-button'}
          className={currentSelectedItem ? 'has-value' : ''}
          onClick={() => setOpened(!opened)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          type="button"
        >
          <label>{label}</label>
          <span className="dropdown-selected-text">
            {currentSelectedItem
              ? currentSelectedItem.text || currentSelectedItem
              : internalSelectRef.current
                ? internalSelectRef.current.queryValue || ' '
                : ' '}
          </span>
        </button>
        {errorMessage ? (
          <span className="error-message">{errorMessage}</span>
        ) : null}
        {dropDownRender(currentItems)}
      </DropDownListStyles>
    );
  }

  //*********************************************************
  //                 SUGGESTION BOX ONLY
  //VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV

  // these functions are specific for the SuggestionBox
  if (RENDER_AS_SUGGESTION_BOX) {
    //eslint-disable-next-line
    const [runFilter, setRunFilter] = useState(false);

    const filterItems = (text) => {
      if (!text) {
        return items || [];
      }

      // filter out items that match the typed in text
      const returnItems = items
        ? items.filter((item) => {
            const itemText = (item.text || item).toLowerCase();
            if (itemText) {
              return itemText.indexOf(text) > -1;
            }

            return false;
          })
        : [];

      // if highlightMatch is true, then we returned a new set of objects that contain things to allow a highlighted version of the
      // text to display,  the render looks for a __renderedComponent first, then the text property and finally the item itself
      // so we simply utilize that __renderedComponent property
      if (highlightMatch) {
        return returnItems.map((item, index) => {
          const itemText = item.text || item;
          const textIndex = itemText.toLowerCase().indexOf(text);

          return {
            text: item.text || item,
            value: getComponentItemValue(item),
            __hiddenObject: item, // this is here so we can pass the right object during the onItemSelected event
            __renderedComponent: [
              itemText.substring(0, textIndex), // if an item has a __renderedComponent, this will be used instead of the text
              <span key={id + '-highlight-' + index} className="highlight">
                {itemText.substring(textIndex, textIndex + text.length)}
              </span>,
              itemText.substring(textIndex + text.length),
            ],
          };
        });
      }

      return returnItems;
    };

    //eslint-disable-next-line
    const handleKeyUp = useDebounce(
      internalTextBoxRef,
      () => {
        if (runFilter) {
          const filteredItems = filterItems(
            (suggestedText || '').toLowerCase()
          );
          setCurrentItems(filteredItems);
          setOpened(true);
          setRunFilter(false);
          setCurrentSelectedItem(
            filteredItems.filter((item) => {
              return (
                internalTextBoxRef.current.value.toLowerCase() ===
                (item.text !== undefined ? item.text : item).toLowerCase()
              );
            })[0]
          );
        }
      },
      200
    );

    const getTextDisplay = () => {
      return (
        suggestedText ||
        (internalSelectRef.current && internalSelectRef.current.queryValue) ||
        ''
      );
    };

    return (
      <SuggestionBoxStyles
        {...props}
        className={'suggestion-box dropdown-list ' + classes.join(' ')}
        onClick={() => {
          internalTextBoxRef.current.focus();
        }}
        id={id + '-container'}
        ref={clickableAreaRef}
      >
        <TextField
          disabled={disabled}
          errorMessage={errorMessage}
          id={id + '-text'}
          automationId={id + '-text'}
          autoComplete="off"
          name={id + '-text'}
          key={id || ''}
          onChange={(e) => {
            updateSuggestedText(e.target.value);
            setRunFilter(useFilter);
            if (onTextChange) {
              onTextChange(e);
            }
          }}
          label={label}
          onClick={() => setOpened(true)}
          onFocus={() => setOpened(true)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          className={`dropdown-textbox ${hasValueClass}`}
          ref={internalTextBoxRef}
          textFieldStyle={dropDownStyle}
          value={getTextDisplay()}
        />
        {dropDownRender(currentItems)}
      </SuggestionBoxStyles>
    );
  }
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  //                 SUGGESTION BOX ONLY
  //**********************************************************
  // these functions are specific for the SuggestionBox
};

DropDownList.defaultProps = {
  automationId: '',
  disabled: false,
  id: false,
  errorMessage: '',
  label: '',
  items: [],
  onChange: null,
  showClearIcon: false,
  defaultValue: '',
  selectedItem: null,
  selectedValue: '',
  dropDownStyle: 'primary',
};

DropDownList.propTypes = {
  automationId: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  items: PropTypes.array,
  onChange: PropTypes.func,
  showClearIcon: PropTypes.bool,
  defaultValue: PropTypes.string,
  selectedItem: PropTypes.string,
  selectedValue: PropTypes.string,
  dropDownStyle: PropTypes.oneOf([
    'primary',
    'primary-background',
    'outlined',
    'compact',
  ]),
  highlightMatch: PropTypes.bool,
  ariaLabel: PropTypes.string,
  onTextChange: PropTypes.func,
  itemTextDisplay: PropTypes.oneOf(['', 'text', 'value']),
  disableFilter: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  selectRef: PropTypes.any,
  textBoxRef: PropTypes.any,
};

export default DropDownList;

export const SuggestionBox = ({ ...props }) => {
  return DropDownList({ ...props, showClearIcon: true }, true);
};

SuggestionBox.defaultProps = {
  automationId: '',
  disabled: false,
  id: '',
  errorMessage: '',
  highlightMatch: false,
  label: '',
  items: [],
  onChange: null,
  showClearIcon: false,
  defaultValue: '',
  selectedItem: null,
  selectedValue: '',
  onTextChange: null,
  itemTextDisplay: '',
  dropDownStyle: 'primary',
  disableFilter: false,
};
